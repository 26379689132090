.root {
  margin: 0 auto;
  max-width: 640px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
  color: #ffffff;
  background-color: #0d3b8b;
  text-align: center;
  line-height: 1;
}

.header .primary {
  font-size: 1.75rem;
}

.header .secondary {
  margin-top: .25rem;
  font-size: .875rem;
  color: rgba(255, 255, 255, .6);
}

.body {
  padding-top: 1rem;
}

.descriptions {
  margin: .875rem;
}

.descriptions .title {
  text-align: center;
}

.footer {
  margin-bottom: 4rem;
  text-align: center;
  font-size: .75rem;
  color: #cccccc;
}

.footer > .thumb {
  display: block;
  width: 100%;
}